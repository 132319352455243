import React from 'react'
import { FaInstagram, FaTwitter, FaLinkedin, FaGithub, FaEnvelope, FaGamepad, FaDesktop } from 'react-icons/fa'

const Home = () =>
  <div className="flex justify-center items-center h-full">
    <div className="text-center border-2 border-gray-100 rounded-lg space-y-8 p-8 mx-8 max-w-xl lg:hover:scale-110 transition-transform">
      <FaDesktop className='text-4xl inline-flex'/>
      <div className="text-2xl md:text-4xl">D'ARCY RAIL-IP</div>
      <div className='space-y-4'>
        <div>
          VP of Technology at <a href='https://www.cinesend.com' rel="noopen noreferrer" target="_blank" className="underline">CineSend</a> in Toronto, Canada.
        </div>
        <div>
          Building people, products, and processes.
        </div>
      </div>
      <div>
        <ul className='flex justify-around mx-12'>
          {links.map(link => {
            const Icon = link.icon
            return (
              <li key={link.url}>
                <a href={link.url} rel="noopener noreferrer" target={link.url[0] === "/" ? "" : "_blank"} >
                  <Icon/>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  </div>

const links = [
  { url: "https://linkedin.com/in/darcyrailip", icon: FaLinkedin },
  { url: "https://twitter.com/darcyrailip", icon: FaTwitter },
  // { url: "https://instagram.com/darcyrailip", icon: FaInstagram },
  { url: "https://github.com/darcyrailip", icon: FaGithub },
  { url: "mailto:darcyrailip@gmail.com", icon: FaEnvelope },
  { url: "/games", icon: FaGamepad }
]

export default Home
