import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import Home from '../components/home'
import Games from '../components/games'
import NotFound from '../components/not_found'
import Background from '../images/bg.jpg'

const Container = () => 
  <div className={`text-white fixed inset-0 font-mono transition-transform`}>
    <div className='flex flex-col justify-between h-full'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/games" element={<Games/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
      <footer className='text-center text-sm mb-4 font-thin'>
        <p className="copyright">D'ARCY RAIL-IP {new Date().getFullYear()}</p>
      </footer>
    </div>
    <div className="fixed top-0 left-0 right-0 bottom-0 opacity-50 -z-10">
      <div className='w-full h-full' style={{ backgroundImage: `url(${Background})` }}/>
    </div>
  </div>
  
export default Container