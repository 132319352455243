import React from 'react'
import { Link } from "react-router-dom"

const NotFound = () =>
  <div className="flex justify-center items-center h-full">
    <Link to='/games'>
      <div className="text-center border-2 border-gray-100 rounded-lg space-y-8 p-8 mx-8 max-w-xl lg:hover:scale-110 transition-transform">
        <div className="text-xl">
          I couldn't find what you were looking for... Click here to play a game!
        </div>
      </div>
    </Link>
  </div>

export default NotFound
